import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { UserApi } from "../api/UserApi";

export default function Login() {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [redirect, setRedirect] = React.useState(false);

  const toast = useToast();

  if (redirect === true) {
    return <Navigate to="/report" />;
  }

  async function doLogin() {
    const user = await UserApi.login({
      password,
      phone,
    });

    if (user.userUid && (user as any).ok !== false) {
      console.log("user", user);
      localStorage.setItem("@rfmtools/uid", user.userUid);
      console.log("href", window.location.href);
      setRedirect(true);
    } else {
      toast({
        title: "Error",
        description: "Invalid email and/or password",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 20px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <Text fontSize={36} fontWeight="bold">
          RFM Tools
        </Text>
        <Flex flexDirection="column">
          <FormControl>
            <FormLabel htmlFor="phone">Phone</FormLabel>
            <Input
              onFocus={(event) => event.target.select()}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              id="phone"
              type="text"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              onFocus={(event) => event.target.select()}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              type="password"
            />
          </FormControl>
          <Button onClick={doLogin} marginTop={15}>
            Login
          </Button>
        </Flex>
      </Box>
    </div>
  );
}
