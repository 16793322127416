import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  List,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  CreateStripeIntentIRoute,
  IStripeCreateSession,
  PaymentApi,
} from "../api/PaymentApi";

export default function Payment() {
  const [payment, setPayment] = useState<CreateStripeIntentIRoute>({
    amount: 0,
    email: "",
    productDescription: "",
  });

  const toast = useToast();

  const [loading, setLoading] = useState(false);

  async function submit() {
    if (loading) return;

    try {
      setLoading(true);

      if (!payment.email || !payment.productDescription || !payment.amount)
        return;

      const result = await PaymentApi.createStripeSession(payment);

      toast({
        title: "Success",
        description: "Payment Succeeded",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setPayment({
        amount: 0,
        email: "",
        productDescription: "",
      });

      console.log("Payment succedded", result);
    } catch (err) {
      console.log(err);
      toast({
        title: "Error",
        description: "Unexpected Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box padding="25px">
      <Flex flexDirection="column" maxWidth={700}>
        <Text fontWeight="bold" fontSize={32}>
          Create a new Payment
        </Text>

        <FormControl>
          <FormLabel htmlFor="email">Customer Email</FormLabel>
          <Input
            value={payment.email}
            onChange={(e) => setPayment({ ...payment, email: e.target.value })}
            id="email"
            type="email"
          />
        </FormControl>

        <Text fontWeight="semibold" marginTop={5} fontSize={26}>
          Product
        </Text>

        <FormControl>
          <FormLabel htmlFor="prodName">Product Name</FormLabel>
          <Input
            value={payment.productDescription}
            onChange={(e) =>
              setPayment({ ...payment, productDescription: e.target.value })
            }
            id="prodName"
            type="text"
          />
        </FormControl>
        <Flex gap={5}>
          <FormControl>
            <FormLabel htmlFor="prodPrice">Product Price</FormLabel>
            <Input
              value={payment.amount}
              onChange={(e) =>
                setPayment({ ...payment, amount: e.target.valueAsNumber })
              }
              id="prodPrice"
              type="number"
            />
          </FormControl>
        </Flex>

        <hr style={{ margin: "15px 0px" }} />
        <Button isLoading={loading} onClick={submit} bg="green.200">
          Create payment
        </Button>
      </Flex>
    </Box>
  );
}
