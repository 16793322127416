import { Box, Flex, List, Text } from "@chakra-ui/react";
import { colorSchemes } from "@nivo/colors";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ContactApi, IContactWithRFM } from "../api/ContactApi";
import { User, UserApi } from "../api/UserApi";
import { CUSTOM_SCHEME } from "../utils/NivoColorScheme";

export type Data = {
  key: string;
  R: number;
  M: number;
  F: number;
  // [x: string]: number | string;
};

const ChartDiv = styled.div`
  height: 650px;
  width: 700px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
`;

export type TooltipComponent = {
  color: string;
  height: number;
  id: string;
  labelTextColor: string;
  opacity: number;
  value: number;
  width: number;
  x: number;
  xKey: "R" | "M" | "F";
  y: number;
  yKey: "1" | "2" | "3" | "4" | "5";
};

function getStandardData(key: string): Data {
  return {
    key,
    R: 0,
    M: 0,
    F: 0,
  };
}

export default function Report() {
  const [chartData, setChartData] = useState<Data[]>([]);
  const [contacts, setContacts] = useState<IContactWithRFM[]>([]);

  const [user, setUser] = useState<User>();

  useEffect(() => {
    (async () => {
      const newUser = await UserApi.getUser();
      setUser(newUser);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const newContacts = await ContactApi.getAll();
        console.log("newContacts", contacts);
        if (!(newContacts as any).error) setContacts(newContacts);
      } catch (err) {
        console.log("err", err);
      }
    })();
  }, []);

  const dataHandlerGenerator = (): Data[] => {
    if (!user) return [];

    let dataArray: Data[] = [
      getStandardData("1"),
      getStandardData("2"),
      getStandardData("3"),
      getStandardData("4"),
      getStandardData("5"),
    ];

    contacts.map((contact) => {
      if (contact.rfm.R === 0 || contact.rfm.F === 0 || contact.rfm.M === 0)
        return;
      dataArray[contact.rfm.R - 1]["R"] += 1;
      dataArray[contact.rfm.F - 1]["F"] += 1;
      dataArray[contact.rfm.M - 1]["M"] += 1;
    });

    return dataArray.reverse();
  };

  useEffect(() => {
    if (!contacts.length || !user) return;

    setChartData(dataHandlerGenerator());
  }, [contacts, user]);

  return (
    <Box padding="25px">
      <ChartDiv>
        <Text width="100%" paddingLeft={10} fontWeight="bold" fontSize={32}>
          RFM Chart
        </Text>
        <ResponsiveHeatMap
          data={chartData}
          keys={["R", "F", "M"]}
          indexBy="key"
          margin={{ top: 30, right: 40, bottom: 30, left: 40 }}
          forceSquare={false}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "top",
            tickSize: 5,
            tickPadding: 5,
            legend: "",
            legendOffset: 36,
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
          }}
          tooltip={(e: TooltipComponent) => {
            let users = contacts
              .map((user) => {
                if (user.rfm.R === 0 || user.rfm.F === 0 || user.rfm.M === 0)
                  return undefined;

                if (user.rfm[e.xKey] === parseInt(e.yKey)) return user.email;
              })
              .filter((value) => value != undefined);
            return (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "bold" }}>Users on this cell:</span>
                {users.map((user) => (
                  <span>{user}</span>
                ))}
              </div>
            );
          }}
          cellOpacity={1}
          cellBorderColor={{ from: "color", modifiers: [["darker", 0.4]] }}
          labelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          animate={true}
          motionStiffness={80}
          motionDamping={9}
          hoverTarget="cell"
          cellHoverOthersOpacity={0.75}
          // @ts-ignore
          colors={CUSTOM_SCHEME}
        />
      </ChartDiv>
      <Box>
        <Text fontWeight="bold" marginTop={5} fontSize={24}>
          Customers Info
        </Text>
        <Flex width={500}>
          <Text width={200}>Email</Text>
          <Text width={100}>R</Text>
          <Text width={100}>F</Text>
          <Text width={100}>M</Text>
        </Flex>
        <List>
          {contacts.map((c, i) => {
            return (
              <Flex
                backgroundColor={i % 2 === 0 ? "#e8e8e8" : "#fff"}
                width={500}
              >
                <Text width={200}>{c.email}</Text>
                <Text width={100}>{c.rfm.R}</Text>
                <Text width={100}>{c.rfm.F}</Text>
                <Text width={100}>{c.rfm.M}</Text>
              </Flex>
            );
          })}
        </List>
      </Box>
    </Box>
  );
}
