export const CUSTOM_SCHEME = [
  ["#F98973"],
  ["#F6806A"],
  ["#F37760"],
  ["#F78D6B"],
  ["#FBA476"],
  ["#FFD97D"],
  ["#AAF683"],
  ["#85E58C"],
  ["#73DC90"],
  ["#60D394"],
];

export const CUSTOM_SCHEME_2 = [
  ["#EE6055"],
  ["#F18077"],
  ["#FF9B85"],
  ["#FFD97D"],
  ["#80DCA9"],
  ["#60D394"],
  ["#AAF683"],
];
