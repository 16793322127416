import { ChakraProvider } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import WithSidebar from "./components/WithSidebar";
import Login from "./views/Login";
import Payment from "./views/Payment";
import Report from "./views/Report";
import Settings from "./views/Settings";
export default function Router() {
  return (
    <ChakraProvider>
      <Routes>
        <Route
          path="/"
          element={
            <WithSidebar>
              <Navigate to="/report" />
            </WithSidebar>
          }
        />
        <Route path="login" element={<Login />} />

        <Route
          path="settings"
          element={
            <WithSidebar>
              <Settings />
            </WithSidebar>
          }
        />

        <Route
          path="payment"
          element={
            <WithSidebar>
              <Payment />
            </WithSidebar>
          }
        />

        <Route
          path="report"
          element={
            <WithSidebar>
              <Report />
            </WithSidebar>
          }
        />
      </Routes>
    </ChakraProvider>
  );
}
