import { SERVER_URL } from "../config";

export interface IStripeCreateSession {
  line_items: {
    price_data: {
      currency: string;
      product_data: {
        name: string;
      };
      unit_amount: number;
    };
    quantity: number;
  }[];
  customer_email: string;
}

export interface CreateStripeIntentIRoute {
  amount: number;
  email: string;
  productDescription: string;
}

export const PaymentApi = {
  createStripeSession: async (data: CreateStripeIntentIRoute) => {
    const userUid = localStorage.getItem("@rfmtools/uid");

    const response = await fetch(`${SERVER_URL}/stripe/payment`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...data, userUid }),
    });

    return response.json();
  },
};
