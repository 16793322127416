import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  List,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  BMUserVariables,
  IUpdateUserIRoute,
  StripeIntegration,
  UserApi,
} from "../api/UserApi";
import { MillisecondConvesion } from "../utils/MillisecondsConversion";
import { RFM_DEFAULT } from "../utils/RFMDefault";

const IndexLine = styled(Flex)`
  justify-content: center;
  font-weight: semibold;
  height: 40px;
  align-items: center;
  margin-top: var(--chakra-space-1);
`;

function generateEmptyArray(length: number) {
  if (length < 0) return [];
  return Array.from({ length }, () => 0);
}

export default function Settings() {
  const [rArray, setRArray] = useState<Array<number | null>>([]);
  const [mArray, setMArray] = useState<Array<number | null>>([]);
  const [fArray, setFArray] = useState<Array<number | null>>([]);

  const [numberOfDivisions, setNumberOfDivisions] = useState(0);

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  const toast = useToast();

  const [stripe, setStripe] = useState<StripeIntegration>({
    productionKeys: {
      privateKey: "",
      publicKey: "",
    },
    testKeys: {
      privateKey: "",
      publicKey: "",
    },
  });

  const [berserkermail, setBerserkermail] = useState<BMUserVariables>();

  const [loading, setLoading] = useState(false);

  async function updateUser() {
    if (loading) return;

    try {
      setLoading(true);

      // Check RFM configuration
      for (let i = 0; i < 4; i++) {
        if (rArray[i] === null || mArray[i] === null || fArray[i] === null) {
          toast({
            title: "Error",
            description: "Missing RFM Configuration value",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
      }

      const newUser: IUpdateUserIRoute = {
        email: email,
        name: name,
        rfmConfig: {
          numberOfDivisions: numberOfDivisions,
          recency: rArray as number[],
          monetary: mArray as number[],
          frequency: fArray as number[],
        },
        bmIntegrationVariables: berserkermail,
        stripeIntegrationVariables: stripe,
      };

      const response = await UserApi.updateUser(newUser);

      toast({
        title: "Success",
        description: "User updated with success",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Error",
        description: "Unexpected Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      const user = await UserApi.getUser();

      if (!user) return;

      setNumberOfDivisions(user.rfmConfig.numberOfDivisions);
      setRArray(user.rfmConfig.recency);
      setMArray(user.rfmConfig.monetary);
      setFArray(user.rfmConfig.frequency);

      setPhone(user.phone);
      setEmail(user.email);
      setName(user.name);

      if (user.stripeIntegrationVariables)
        setStripe(user.stripeIntegrationVariables);
      setBerserkermail(user.bmIntegrationVariables);
    })();
  }, []);

  function resetRFM() {
    setNumberOfDivisions(RFM_DEFAULT.numberOfDivisions);
    setRArray(RFM_DEFAULT.recency);
    setFArray(RFM_DEFAULT.frequency);
    setMArray(RFM_DEFAULT.monetary);

    onClose();
  }

  return (
    <Box padding="25px">
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Reset RFM
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to reset your RFM Configuration to the
              default settings?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={resetRFM} ml={3}>
                Reset
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Flex maxWidth={700} flexDirection="column">
        <FormControl>
          <FormLabel htmlFor="phone">Phone</FormLabel>
          <Input defaultValue={phone} readOnly={true} id="phone" type="phone" />
        </FormControl>
        <FormControl marginTop={25}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            type="email"
          />
          <FormHelperText>Your email</FormHelperText>
        </FormControl>
        <FormControl marginTop={25}>
          <FormLabel htmlFor="name">Name</FormLabel>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="name"
            type="text"
          />
          <FormHelperText>Your full name</FormHelperText>
        </FormControl>
        <hr style={{ margin: "25px 0px" }} />
        <Text
          marginTop={25}
          marginBottom={25}
          fontWeight="medium"
          fontSize="larger"
        >
          RFM Configuration
        </Text>
        {/* <FormControl>
          <FormLabel htmlFor="numberOfDivisions">Number of Divisions</FormLabel>
          <Input
            onFocus={(event) => event.target.select()}
            value={numberOfDivisions}
            onChange={(e) => setNumberOfDivisions(e.target.valueAsNumber || 0)}
            id="numberOfDivisions"
            type="number"
          />
          <FormHelperText>The number of divisions for RFM</FormHelperText>
        </FormControl> */}
        <Flex gap={2}>
          <List marginRight={5}>
            <Text textAlign="center" fontWeight="bold">
              Value
            </Text>
            <IndexLine>5</IndexLine>
            <IndexLine>4</IndexLine>
            <IndexLine>3</IndexLine>
            <IndexLine>2</IndexLine>
            <IndexLine>1</IndexLine>
          </List>
          {rArray.length > 0 && (
            <List>
              <Text fontWeight="bold">Recency (Days)</Text>
              <Input
                borderColor="red"
                readOnly
                value={
                  rArray[3] !== null
                    ? `Less than ${
                        rArray[3] / MillisecondConvesion.DAY_IN_MS
                      } days`
                    : ""
                }
                color="#cacaca"
                height="40px"
                alignItems="center"
                marginTop={1}
              />
              {rArray
                .map((v, i) => (
                  <Input
                    borderColor="red"
                    key={`r-${i}`}
                    onFocus={(event) => event.target.select()}
                    marginTop={1}
                    type="number"
                    value={v !== null ? v / MillisecondConvesion.DAY_IN_MS : ""}
                    onChange={(e) =>
                      setRArray(
                        rArray.map((value, index) =>
                          index === i
                            ? e.target.valueAsNumber *
                                MillisecondConvesion.DAY_IN_MS || null
                            : value
                        )
                      )
                    }
                  />
                ))
                .reverse()}
            </List>
          )}
          {fArray.length > 0 && (
            <List>
              <Text fontWeight="bold">Frequency (Purchases)</Text>
              <Input
                borderColor="yellow.400"
                readOnly
                value={fArray[3] !== null ? `More than ${fArray[3]} purchases` : ""}
                color="#cacaca"
                height="40px"
                alignItems="center"
                marginTop={1}
              />
              {fArray
                .map((v, i) => (
                  <Input
                    borderColor={i === 3 ? "yellow.400" : "red"}
                    key={`f-${i}`}
                    onFocus={(event) => event.target.select()}
                    marginTop={1}
                    type="number"
                    value={v || ""}
                    onChange={(e) =>
                      setFArray(
                        fArray.map((value, index) =>
                          index === i ? e.target.valueAsNumber || null : value
                        )
                      )
                    }
                  />
                ))
                .reverse()}
            </List>
          )}
          {mArray.length > 0 && (
            <List>
              <Text fontWeight="bold">Monetary ($)</Text>
              <Input
                borderColor="green"
                readOnly
                value={mArray[3] !== null ? `More than $${mArray[3]}` : ""}
                color="#cacaca"
                height="40px"
                alignItems="center"
                marginTop={1}
              />
              {mArray
                .map((v, i) => (
                  <Input
                    borderColor="green"
                    key={`m-${i}`}
                    onFocus={(event) => event.target.select()}
                    marginTop={1}
                    type="number"
                    value={v || ""}
                    onChange={(e) =>
                      setMArray(
                        mArray.map((value, index) =>
                          index === i ? e.target.valueAsNumber || null : value
                        )
                      )
                    }
                  />
                ))
                .reverse()}
            </List>
          )}
        </Flex>
        <Button onClick={onOpen} marginTop={5}>
          Reset RFM Configuration
        </Button>
        <hr style={{ margin: "25px 0px" }} />
        <Text
          marginTop={25}
          marginBottom={25}
          fontWeight="medium"
          fontSize="larger"
        >
          Stripe Configuration
        </Text>
        <Text fontWeight="bold">Production keys</Text>
        <FormControl>
          <FormLabel htmlFor="stripe" color="red">
            Stripe Public Key
          </FormLabel>
          <Input
            value={stripe?.productionKeys.publicKey || ""}
            onChange={(e) =>
              setStripe({
                ...stripe,
                productionKeys: {
                  ...stripe?.productionKeys,
                  publicKey: e.target.value,
                },
              })
            }
            id="stripePK"
            type="text"
          />
        </FormControl>
        <FormControl marginTop={15}>
          <FormLabel htmlFor="stripe" color="red">
            Stripe Secret Key
          </FormLabel>
          <Input
            value={stripe?.productionKeys.privateKey || ""}
            onChange={(e) =>
              setStripe({
                ...stripe,
                productionKeys: {
                  ...stripe?.productionKeys,
                  privateKey: e.target.value,
                },
              })
            }
            id="stripeSK"
            type="text"
          />
        </FormControl>
        <Text fontWeight="bold" marginTop={15}>
          Test keys
        </Text>
        <FormControl>
          <FormLabel htmlFor="stripe" color="blue">
            Stripe Public Test Key
          </FormLabel>
          <Input
            value={stripe?.testKeys.publicKey || ""}
            onChange={(e) =>
              setStripe({
                ...stripe,
                testKeys: { ...stripe?.testKeys, publicKey: e.target.value },
              })
            }
            id="stripePK"
            type="text"
          />
        </FormControl>
        <FormControl marginTop={15}>
          <FormLabel htmlFor="stripe" color="blue">
            Stripe Secret Test Key
          </FormLabel>
          <Input
            value={stripe?.testKeys.privateKey || ""}
            onChange={(e) =>
              setStripe({
                ...stripe,
                testKeys: { ...stripe?.testKeys, privateKey: e.target.value },
              })
            }
            id="stripeSK"
            type="text"
          />
        </FormControl>

        <hr style={{ margin: "25px 0px" }} />
        <Text
          marginTop={25}
          marginBottom={25}
          fontWeight="medium"
          fontSize="larger"
        >
          BerserkerMail Configuration
        </Text>
        <FormControl>
          <FormLabel htmlFor="stripe" color="orange.400">
            Berserkermail API Key
          </FormLabel>
          <Input
            value={berserkermail?.apiKey || ""}
            onChange={(e) => setBerserkermail({ apiKey: e.target.value })}
            id="stripePK"
            type="text"
          />
        </FormControl>

        <Button
          isLoading={loading}
          marginTop={2}
          color="green.400"
          onClick={updateUser}
        >
          Save
        </Button>
      </Flex>
    </Box>
  );
}
