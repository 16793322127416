import { Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import Sidebar from "./Sidebar";

export default function WithSidebar(props: { children: React.ReactNode }) {
  return (
    <Grid minHeight="100vh" templateColumns="250px calc(100% - 250px)">
      <GridItem>
        <Sidebar />
      </GridItem>
      <GridItem>{props.children}</GridItem>
    </Grid>
  );
}
