import { SERVER_URL } from "../config";

export interface Contact {
  _id?: string;

  // Owner of the contact
  userUid: string;

  email: string;
  phone?: string;
  name?: string;

  // Timestamps
  createdAt: number;
  updatedAt: number;

  // RFM Variables
  lastPurchaseDate: number;
  totalExpended: number;

  purchases: Purchase[];
}

export interface Purchase {
  value: number;

  // Timestamp
  createdAt: number;

  // stripeVariables?: StripeIntegrationPurchaseVariables;
}

export interface RFMVariables {
  // Recency
  R: number;

  // Frequency
  F: number;

  // Monetary
  M: number;

  total: number;
}

export type IContactWithRFM = Contact & { rfm: RFMVariables };

export const ContactApi = {
  getAll: async () => {
    const userUid = localStorage.getItem("@rfmtools/uid");
    const response = await fetch(`${SERVER_URL}/contact/${userUid}/all`);

    return response.json() as Promise<IContactWithRFM[]>;
  },
};
