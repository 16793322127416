import { SERVER_URL } from "../config";

export interface BMUserVariables {
  apiKey: string;
}

export interface StripeKeys {
  publicKey?: string;
  privateKey?: string;
}

export interface StripeIntegration {
  productionKeys: StripeKeys;
  testKeys: StripeKeys;
}

export interface RFMConfig {
  // All the arrays must have the length = numberOfDivisions
  numberOfDivisions: number;

  /**
   * Array of timestamps for dates diffs
   */
  recency: number[];

  /**
   * Array of numbers of purchases
   */
  frequency: number[];

  /**
   * Array of numbers in coin value
   */
  monetary: number[];
}

export interface User {
  authType: "RFMTOOLS" | "LEARNISTIC";

  _id?: string;
  userUid: string;

  phone: string;
  email: string;
  name: string;

  bmIntegrationVariables?: BMUserVariables;
  stripeIntegrationVariables?: StripeIntegration;

  rfmConfig: RFMConfig;

  authToken?: string;
}

export type IUpdateUserIRoute = Partial<
  Omit<User, "_id" | "userUid" | "authType" | "phone" | "authToken">
>;

export const UserApi = {
  login: async (data: { phone: string; password: string }) => {
    const response = await fetch(`${SERVER_URL}/user/login`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return response.json() as Promise<User>;
  },

  getUser: async () => {
    const userUid = localStorage.getItem("@rfmtools/uid");

    const response = await fetch(`${SERVER_URL}/user/${userUid}`);

    return response.json() as Promise<User>;
  },

  updateUser: async (data: IUpdateUserIRoute) => {
    const userUid = localStorage.getItem("@rfmtools/uid");

    const response = await fetch(`${SERVER_URL}/user/${userUid}`, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return response;
  },
};
