import React, { useEffect, useState } from "react";
import { Button, Center, Container, Flex, Text } from "@chakra-ui/react";
import { Link, Navigate } from "react-router-dom";

export default function Sidebar() {
  const [redirect, setRedirect] = useState(false);
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    if (logged) return;
    const uid = localStorage.getItem("@rfmtools/uid");

    if (!uid) {
      setRedirect(true);
    } else {
      setLogged(true);
    }
  }, [logged]);

  if (redirect === true) {
    return <Navigate to="/login" />;
  }

  return (
    <Container
      boxShadow="2px 0 10px -2px #c3c3c3"
      height="100%"
      paddingTop="25px"
    >
      <Flex height="100%" flexDirection="column" justifyContent="space-between">
        <Flex flexDirection="column">
          <Center>
            <Text fontWeight="bold" fontSize="26px">
              RFM Tools
            </Text>
          </Center>
          <Link style={{ width: "100%" }} to="/report">
            <Button width="100%" margin="5px 0px">
              RFM Chart
            </Button>
          </Link>
          <Link to="/settings" style={{ width: "100%" }}>
            <Button width="100%" margin="5px 0px">
              Settings
            </Button>
          </Link>
          <Link to="/payment" style={{ width: "100%" }}>
            <Button width="100%" margin="5px 0px">
              Create Payment
            </Button>
          </Link>
        </Flex>
        <Flex marginBottom="25px">
          <Button
            width="100%"
            onClick={() => {
              localStorage.removeItem("@rfmtools/uid");
              setLogged(false);
            }}
          >
            Logout
          </Button>
        </Flex>
      </Flex>
    </Container>
  );
}
